import { Directive, HostListener, input, output } from '@angular/core';
import dayjs, { Dayjs } from 'dayjs';
import { getNgbDateStructFromDayJs } from '@mwe/utils';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import {
  CLEAN_DATE_REGEX,
  DATE_SEPARATOR,
  DAY_PART_INDEX,
  MONTH_PART_INDEX,
  YEAR_PART_INDEX,
  ZERO_PADDED_DATE_REGEX,
} from '@shared/app.constants';

@Directive({
  selector: '[libDateInputMask]',
  standalone: true,
})
export class DateInputMaskDirective {
  maxDate = input<Dayjs>();
  minDate = input<Dayjs>();
  readonly applyDate = output<NgbDateStruct>();

  @HostListener('input', ['$event'])
  onInput(event: InputEvent): void {
    const input = event.target as HTMLInputElement;
    const cleanValue = this.cleanInput(input.value);
    const parts = cleanValue.split(DATE_SEPARATOR).filter(part => part !== '');

    let formattedValue = this.formatDateParts(parts, cleanValue);
    formattedValue = this.padWithZeros(formattedValue);

    input.value = formattedValue;

    if (this.isValidDateFormat(formattedValue)) {
      const date = this.parseDate(formattedValue);
      if (date && this.isDateInRange(date)) {
        this.applyDate.emit(getNgbDateStructFromDayJs(date));
      }
    }
  }

  private cleanInput(value: string): string {
    return value.replace(CLEAN_DATE_REGEX, '');
  }

  private formatDateParts(parts: string[], originalValue: string): string {
    let result = '';

    if (parts.length > 0) {
      result = this.formatDays(parts);
    }

    if (parts.length > 1) {
      const monthPart = this.formatMonths(parts);
      result += monthPart ? DATE_SEPARATOR + monthPart : '';
    }

    if (parts.length > 2) {
      result += DATE_SEPARATOR + parts[2].substring(0, 4);
    }

    return this.handleDots(result, originalValue);
  }

  private formatDays(parts: string[]): string {
    let days = parts[DAY_PART_INDEX];
    if (days.length > 2) {
      parts.splice(1, 0, days.substring(2));
      days = days.substring(0, 2);
    }

    const daysNum = parseInt(days);
    return daysNum > 31 || daysNum === 0 || days === '00' ? '' : days;
  }

  private formatMonths(parts: string[]): string {
    let months = parts[MONTH_PART_INDEX];
    if (months.length > 2) {
      parts.splice(2, 0, months.substring(2));
      months = months.substring(0, 2);
    }

    const monthsNum = parseInt(months);
    return monthsNum > 12 || monthsNum === 0 || months === '00' ? '' : months;
  }

  private handleDots(value: string, originalValue: string): string {
    let result = value;
    if (originalValue.endsWith(DATE_SEPARATOR)) {
      result += DATE_SEPARATOR;
    }
    if (originalValue.indexOf('..') !== -1) {
      result += DATE_SEPARATOR;
    }
    return result;
  }

  private padWithZeros(value: string): string {
    if (!value.endsWith(DATE_SEPARATOR)) {
      return value;
    }

    const parts = value.split(DATE_SEPARATOR);

    if (parts[DAY_PART_INDEX] && parts[DAY_PART_INDEX].length === 1) {
      value = '0' + value;
    }

    if (parts.length > 1 && parts[MONTH_PART_INDEX] && parts[MONTH_PART_INDEX].length === 1) {
      value = value.replace(ZERO_PADDED_DATE_REGEX, '.0$1.');
    }

    return value;
  }

  private isValidDateFormat(value: string): boolean {
    const parts = value.split(DATE_SEPARATOR);
    return (
      parts.length === 3 &&
      parts[DAY_PART_INDEX].length === 2 &&
      parts[MONTH_PART_INDEX].length === 2 &&
      parts[YEAR_PART_INDEX].length === 4
    );
  }

  private parseDate(value: string): Dayjs | null {
    const [day, month, year] = value.split(DATE_SEPARATOR).map(Number);
    const date = dayjs(`${year}-${month}-${day}`);
    return date.isValid() ? date : null;
  }

  private isDateInRange(date: Dayjs): boolean {
    const max = this.maxDate();
    const min = this.minDate();

    if (max && date.isAfter(max)) return false;
    return !(min && date.isBefore(min));
  }
}
