export enum ContactDataType {
  PRIVATE_INDIVIDUAL = 'privateIndividual',
  ORGANIZATION = 'organization',
}

export const OCCUPIED_CONTRACT_PARTNER_KEY = 'occupiedContractPartner';
export const PRIVATE_INDIVIDUAL_CONTACT_DATA_KEY = 'privateIndividualContactData';
export const ORGANIZATION_CONTACT_DATA_KEY = 'organizationContactData';
export const POSTAL_ADDRESS_KEY = 'postalAddress';
export const INVOICE_ADDRESS_KEY = 'invoiceAddress';

export class OccupiedContractPartner {
  organizationType: string;
  contactDataIndividual: ContactDataIndividual;
  contactDataOrganization: ContactDataOrganization;
  invoiceAddress: AddressForm;
}

export class ContactDataIndividual {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
}

export class ContactDataOrganization {
  name: string;
  uid: string;
  firmenbuchNr: string;
  email: string;
  phoneNumber: string;
}

export class AddressForm {
  plz: string;
  city: string;
  street: string;
  houseNumberStaircase: string;
  door: string;
}
