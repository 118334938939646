import {
  CurrentContractPartner,
  MeterPointChangeState,
  NewContractPartner,
  ProductAndDate,
} from '@ikp/pages/meter-point-change-request/meterpoint-change-request.types';
import { Address } from '@mwe/models';
import { IAddress, IContractPartner, IMeterPointChangeRequest, IProductsAndDate } from '@ikp/model/meterpoint-change-request-http.model';
import { ContractPartnerType } from '@ikp/pages/meter-point-change-request/meter-point-change-request.utils';
import { ContractPartnerEmptyModel } from '@ikp/pages/meter-point-change-request/04_new_contract_partner/new-contract-partner.utils';
import {
  AddressForm,
  ContactDataType,
} from '@ikp/pages/meter-point-change-request/03_current_contract_partner/model/occupied_contract_partner.model';
import { ReRegistrationDateType } from '@ikp/pages/meter-point-change-request/02_product_and_date/components/model/product-selection.model';
import { DATE_FORMAT } from '@mwe/constants';

type ContractPartner = CurrentContractPartner | NewContractPartner;

export class MeterPointChangeTransformer {
  public static mapToIMeterPointChangeRequest(meterPointChangeRequest: MeterPointChangeState): IMeterPointChangeRequest {
    return {
      typeOfChange: meterPointChangeRequest.stepChangeType,
      productsAndDate: this.mapProductsAndDate(meterPointChangeRequest.productAndDate, meterPointChangeRequest.meterPoint.adresse),
      currentContractPartner: this.mapContractPartner(meterPointChangeRequest.currentContractPartner),
      newContractPartner: this.mapContractPartner(meterPointChangeRequest.newContractPartner),
      newInvoiceRecipient: meterPointChangeRequest.newContractPartner.alternativeInvoicingAddress
        ? this.mapContractPartner(meterPointChangeRequest.newContractPartner)
        : null,
    };
  }

  private static mapProductsAndDate(productsAndDate: ProductAndDate, address: Address): IProductsAndDate {
    return {
      date:
        productsAndDate.reRegistrationDateSelection === ReRegistrationDateType.SPECIFIC_DATE
          ? productsAndDate.reRegistrationDate.format(DATE_FORMAT)
          : null,
      meterPointAddress: this.mapIAddress(address),
      meterPointSelection: productsAndDate.meterPoints.map(meterPoint => ({
        id: meterPoint.id,
        productCategory: meterPoint.category.toUpperCase(),
        meterPointNumber: meterPoint.meterPointNumber,
        meterNumber: meterPoint.meterNumber,
        meterCount: meterPoint.meterCount,
      })),
    };
  }

  private static mapContractPartner(contractPartner: CurrentContractPartner | NewContractPartner): IContractPartner {
    if (this.isCurrentContractPartner(contractPartner)) {
      return this.mapCurrentContractPartner(contractPartner);
    } else if (this.isNewContractPartner(contractPartner)) {
      return this.mapNewContractPartner(contractPartner);
    } else {
      return null;
    }
  }

  private static mapCurrentContractPartner(contractPartner: CurrentContractPartner): IContractPartner {
    if (contractPartner.emptyContractPartner) {
      return this.mapToEmptyContractPartner(
        contractPartner.emptyContractPartner,
        contractPartner.emptyContractPartner?.invoiceAddress?.address,
      );
    } else if (contractPartner.occupiedContractPartner) {
      const contractPartnerInfo = contractPartner.occupiedContractPartner;
      return {
        contractPartnerType:
          contractPartnerInfo.organizationType === ContactDataType.PRIVATE_INDIVIDUAL
            ? ContractPartnerType.PRIVATE
            : ContractPartnerType.COMPANY,
        firstName: contractPartnerInfo.contactDataIndividual?.firstName,
        lastName: contractPartnerInfo.contactDataIndividual?.lastName,
        birthday: null,
        email: contractPartnerInfo.contactDataIndividual?.email ?? contractPartnerInfo.contactDataOrganization?.email,
        phoneNumber: contractPartnerInfo.contactDataIndividual?.phoneNumber ?? contractPartnerInfo.contactDataOrganization?.phoneNumber,
        organisationName: contractPartnerInfo.contactDataOrganization?.name,
        uid: contractPartnerInfo.contactDataOrganization?.uid,
        firmenbuchNr: contractPartnerInfo.contactDataOrganization?.firmenbuchNr,
        address: contractPartnerInfo.invoiceAddress,
      };
    } else {
      return null;
    }
  }

  private static mapNewContractPartner(contractPartner: NewContractPartner): IContractPartner {
    if (contractPartner.emptyPartner) {
      return this.mapToEmptyContractPartner(contractPartner.emptyPartner, contractPartner.alternativeInvoicingAddress.address);
    } else if (contractPartner.formData) {
      const partnerInfo = contractPartner.formData;
      return {
        contractPartnerType: partnerInfo.partnerType,
        firstName: partnerInfo.privatePartner?.firstName,
        lastName: partnerInfo.privatePartner?.lastName,
        birthday: partnerInfo.privatePartner?.birthDate,
        email: partnerInfo.privatePartner?.email,
        phoneNumber: partnerInfo.privatePartner?.phone,
        organisationName: partnerInfo.organizationPartner?.name,
        uid: partnerInfo.organizationPartner?.uidNumber,
        firmenbuchNr: partnerInfo.organizationPartner?.firmenbuchNumber,
        address: contractPartner?.alternativeInvoicingAddress?.address,
      };
    }
    return null;
  }

  private static mapToEmptyContractPartner(
    contractPartner: ContractPartnerEmptyModel,
    invoiceAddress: AddressForm | null | undefined,
  ): IContractPartner {
    return {
      contractPartnerType: ContractPartnerType.COMPANY,
      organisationName: contractPartner.company,
      address: invoiceAddress ?? contractPartner.address,
    };
  }

  private static mapIAddress(address: Address): IAddress {
    return {
      plz: address.postcode,
      city: address.city,
      street: address.street,
      houseNumberStaircase: address.streetNumber,
      door: address.doorNumber,
    };
  }

  private static isCurrentContractPartner(contractPartner: ContractPartner): contractPartner is CurrentContractPartner {
    return 'occupiedContractPartner' in contractPartner || 'emptyContractPartner' in contractPartner;
  }

  private static isNewContractPartner(contractPartner: ContractPartner): contractPartner is NewContractPartner {
    return 'formData' in contractPartner && 'partnerType' in contractPartner.formData;
  }
}
