import { Component, input, output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiComponentsModule } from '@mwe/ui/components';
import { HeaderLogoComponent } from './header-logo/header-logo.component';
import { HeaderLogoutComponent } from './header-logout/header-logout.component';
import { HeaderMenuComponent } from './header-menu/header-menu.component';
import { NgbCollapse } from '@ng-bootstrap/ng-bootstrap';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'lib-ikp-header',
  standalone: true,
  imports: [CommonModule, UiComponentsModule, HeaderLogoComponent, HeaderLogoutComponent, HeaderMenuComponent, NgbCollapse, RouterLink],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent {
  isLoggedIn = input<boolean>(false);
  isCollapsed = input<boolean>(false);
  startPageLink = input<string>('');
  popupService = input<any>();
  readonly toggleNav = output<void>();

  toggleNavbar(): void {
    this.toggleNav.emit();
  }
}
