import { Component, Input } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';

@Component({
  selector: 'lib-ikp-header-logout',
  standalone: true,
  imports: [CommonModule, NgOptimizedImage],
  templateUrl: './header-logout.component.html',
  styleUrl: './header-logout.component.scss',
})
export class HeaderLogoutComponent {
  @Input() popupService;
  async logout() {
    this.popupService.handleLogoutPopup();
  }
}
