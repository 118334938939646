import {
  EmptyContractPartner,
  InvoiceAddressForm,
} from '@ikp/pages/meter-point-change-request/03_current_contract_partner/model/empty_contract_partner.model';
import { InfoCardField } from '@ikp/pages/meter-point-change-request/05_summary/summary.component';
import {
  AddressForm,
  ContactDataIndividual,
  ContactDataOrganization,
  OccupiedContractPartner,
} from '@ikp/pages/meter-point-change-request/03_current_contract_partner/model/occupied_contract_partner.model';
import {
  ContractPartnerCompanyModel,
  ContractPartnerEmptyModel,
  ContractPartnerPrivateModel,
} from '@ikp/pages/meter-point-change-request/04_new_contract_partner/new-contract-partner.utils';
import { NewContractPartner } from '@ikp/pages/meter-point-change-request/meterpoint-change-request.types';
import { preparePartnerAddressForFormatter } from '@ikp/pages/meter-point-change-request/meter-point-change-request.utils';

export const EMPTY_VALUE = '---';

export const currentEmptyContractPartnerToFields = (partner: EmptyContractPartner): InfoCardField[] => {
  const emptyContractPartnerFields = [
    {
      label: 'meterPoint.meterPointChangeRequest.currentContractPartnerStep.empty.company',
      value: partner?.company || EMPTY_VALUE,
      contentDivId: 'meterpoint-change-summary-currentpartner-company',
    },
    {
      label: 'meterPoint.meterPointChangeRequest.currentContractPartnerStep.empty.customerNumber',
      value: partner?.customerNumber || EMPTY_VALUE,
      contentDivId: 'meterpoint-change-summary-currentpartner-customerNumber',
    },
    {
      label: 'meterPoint.meterPointChangeRequest.currentContractPartnerStep.empty.address',
      value: preparePartnerAddressForFormatter(partner?.address),
      contentDivId: 'meterpoint-change-summary-currentpartner-address',
    },
  ];
  if (partner?.invoiceAddress) {
    emptyContractPartnerFields.push({
      label: 'meterPoint.meterPointChangeRequest.currentContractPartnerStep.empty.differentInvoiceAddress',
      value: companyInvoiceAddressToString(partner.invoiceAddress),
      contentDivId: 'meterpoint-change-summary-currentpartner-invoiceAddress',
    });
  }
  return emptyContractPartnerFields;
};

export const newEmptyContractPartnerToFields = (
  partner: ContractPartnerEmptyModel,
  invoiceAddress: InvoiceAddressForm,
): InfoCardField[] => {
  const emptyContractPartnerFields = [
    {
      label: 'meterPoint.meterPointChangeRequest.currentContractPartnerStep.empty.company',
      value: partner?.company || EMPTY_VALUE,
      contentDivId: 'meterpoint-change-summary-currentpartner-company',
    },
    {
      label: 'meterPoint.meterPointChangeRequest.currentContractPartnerStep.empty.customerNumber',
      value: partner?.customerNumber || EMPTY_VALUE,
      contentDivId: 'meterpoint-change-summary-currentpartner-customerNumber',
    },
    {
      label: 'meterPoint.meterPointChangeRequest.currentContractPartnerStep.empty.address',
      value: preparePartnerAddressForFormatter(partner?.address),
      contentDivId: 'meterpoint-change-summary-currentpartner-address',
    },
  ];
  if (invoiceAddress) {
    emptyContractPartnerFields.push({
      label: 'meterPoint.meterPointChangeRequest.currentContractPartnerStep.empty.differentInvoiceAddress',
      value: companyInvoiceAddressToString(invoiceAddress),
      contentDivId: 'meterpoint-change-summary-currentpartner-invoiceAddress',
    });
  }
  return emptyContractPartnerFields;
};

const companyInvoiceAddressToString = (invoiceAddress: InvoiceAddressForm): string => {
  return `${invoiceAddress.company}, ${invoiceAddressToString(invoiceAddress.address)}`;
};

const invoiceAddressToString = (address: AddressForm): string => {
  const doorText = address?.door ? `Top ${address.door}` : '';
  return [address?.street, address?.houseNumberStaircase, doorText, address?.plz, address?.city]
    .filter(Boolean) //filters falsy values (empty strings, null, undefined, ...)
    .join(', ');
};

export const currentOccupiedContractPartnerToFields = (partner: OccupiedContractPartner): InfoCardField[] => {
  const currentOccupiedContractPartnerFields: InfoCardField[] = [];
  if (partner?.contactDataIndividual) {
    currentOccupiedContractPartnerFields.push(...contactDataIndividualToFields(partner.contactDataIndividual));
  } else if (partner?.contactDataOrganization) {
    currentOccupiedContractPartnerFields.push(...contactDataOrganizationToFields(partner.contactDataOrganization));
  }

  currentOccupiedContractPartnerFields.push({
    label: 'meterPoint.meterPointChangeRequest.summary.currentPartner.finalInvoice',
    value: invoiceAddressToString(partner?.invoiceAddress) || EMPTY_VALUE,
    contentDivId: 'meterpoint-change-summary-currentpartner-finalinvoice',
  });

  return currentOccupiedContractPartnerFields;
};

export const newContractPartnerToFields = (partner: NewContractPartner) => {
  const partnerFields: InfoCardField[] = [];
  if (partner?.formData.organizationPartner) {
    partnerFields.push(...contractPartnerOrganizationToFields(partner.formData.organizationPartner));
  } else if (partner?.formData.privatePartner) {
    partnerFields.push(...contractPartnerPrivateToFields(partner.formData.privatePartner));
  }

  if (partner?.alternativeInvoicingAddress) {
    partnerFields.push({
      label: 'meterPoint.meterPointChangeRequest.currentContractPartnerStep.invoiceTitle',
      value: companyInvoiceAddressToString(partner.alternativeInvoicingAddress),
      contentDivId: 'meterpoint-change-summary-newPartner-invoiceAdress',
    });
  }

  return partnerFields;
};

const contactDataIndividualToFields = (contact: ContactDataIndividual): InfoCardField[] => {
  return [
    {
      label: 'meterPoint.meterPointChangeRequest.currentContractPartnerStep.contactData.firstName',
      value: contact?.firstName || EMPTY_VALUE,
      contentDivId: 'meterpoint-change-summary-currentpartner-firstname',
    },
    {
      label: 'meterPoint.meterPointChangeRequest.currentContractPartnerStep.contactData.lastName',
      value: contact?.lastName || EMPTY_VALUE,
      contentDivId: 'meterpoint-change-summary-currentpartner-lastname',
    },
    {
      label: 'meterPoint.meterPointChangeRequest.currentContractPartnerStep.contactData.email',
      value: contact?.email || EMPTY_VALUE,
      contentDivId: 'meterpoint-change-summary-currentpartner-email',
    },
    {
      label: 'meterPoint.meterPointChangeRequest.currentContractPartnerStep.contactData.phoneNumber',
      value: contact?.phoneNumber || EMPTY_VALUE,
      contentDivId: 'meterpoint-change-summary-currentpartner-phone',
    },
  ];
};

const contactDataOrganizationToFields = (contact: ContactDataOrganization): InfoCardField[] => {
  return [
    {
      label: 'meterPoint.meterPointChangeRequest.currentContractPartnerStep.contactData.companyName',
      value: contact?.name || EMPTY_VALUE,
      contentDivId: 'meterpoint-change-summary-currentpartner-companyname',
    },
    {
      label: 'meterPoint.meterPointChangeRequest.currentContractPartnerStep.contactData.firmenbuchNr',
      value: contact?.firmenbuchNr || EMPTY_VALUE,
      contentDivId: 'meterpoint-change-summary-currentpartner-companyregisternumber',
    },
    {
      label: 'meterPoint.meterPointChangeRequest.currentContractPartnerStep.contactData.uidNr',
      value: contact?.uid || EMPTY_VALUE,
      contentDivId: 'meterpoint-change-summary-currentpartner-uidnumber',
    },
    {
      label: 'meterPoint.meterPointChangeRequest.currentContractPartnerStep.contactData.email',
      value: contact?.email || EMPTY_VALUE,
      contentDivId: 'meterpoint-change-summary-currentpartner-email',
    },
    {
      label: 'meterPoint.meterPointChangeRequest.currentContractPartnerStep.contactData.phoneNumber',
      value: contact?.phoneNumber || EMPTY_VALUE,
      contentDivId: 'meterpoint-change-summary-currentpartner-phone',
    },
  ];
};

const contractPartnerOrganizationToFields = (organizationPartner: ContractPartnerCompanyModel) => {
  return [
    {
      label: 'meterPoint.meterPointChangeRequest.contractPartnerType.forms.company.name',
      value: organizationPartner?.name || EMPTY_VALUE,
      contentDivId: 'meterpoint-change-summary-newpartner-name',
    },
    {
      label: 'meterPoint.meterPointChangeRequest.contractPartnerType.forms.company.birthDate',
      value: organizationPartner?.birthDate || EMPTY_VALUE,
      contentDivId: 'meterpoint-change-summary-newpartner-birthdate',
    },
    {
      label: 'meterPoint.meterPointChangeRequest.contractPartnerType.forms.company.uidNumber',
      value: organizationPartner?.uidNumber || EMPTY_VALUE,
      contentDivId: 'meterpoint-change-summary-newpartner-uidnumber',
    },
    {
      label: 'meterPoint.meterPointChangeRequest.contractPartnerType.forms.company.firmenbuchNumber',
      value: organizationPartner?.firmenbuchNumber || EMPTY_VALUE,
      contentDivId: 'meterpoint-change-summary-newpartner-firmenbuchnumber',
    },
    {
      label: 'meterPoint.meterPointChangeRequest.contractPartnerType.forms.company.email',
      value: organizationPartner?.email || EMPTY_VALUE,
      contentDivId: 'meterpoint-change-summary-newpartner-email',
    },
    {
      label: 'meterPoint.meterPointChangeRequest.contractPartnerType.forms.company.phone',
      value: organizationPartner?.phone || EMPTY_VALUE,
      contentDivId: 'meterpoint-change-summary-newpartner-phone',
    },
  ];
};

const contractPartnerPrivateToFields = (privatePartner: ContractPartnerPrivateModel) => {
  return [
    {
      label: 'meterPoint.meterPointChangeRequest.contractPartnerType.forms.privatePerson.birthDate.label',
      value: privatePartner?.birthDate || EMPTY_VALUE,
      contentDivId: 'meterpoint-change-summary-newpartner-birthdate',
    },
    {
      label: null,
      value: null,
      contentDivId: null,
    },
    {
      label: 'meterPoint.meterPointChangeRequest.contractPartnerType.forms.privatePerson.firstName',
      value: privatePartner?.firstName || EMPTY_VALUE,
      contentDivId: 'meterpoint-change-summary-newpartner-firstname',
    },
    {
      label: 'meterPoint.meterPointChangeRequest.contractPartnerType.forms.privatePerson.lastName',
      value: privatePartner?.lastName || EMPTY_VALUE,
      contentDivId: 'meterpoint-change-summary-newpartner-lastname',
    },
    {
      label: 'meterPoint.meterPointChangeRequest.contractPartnerType.forms.privatePerson.email',
      value: privatePartner?.email || EMPTY_VALUE,
      contentDivId: 'meterpoint-change-summary-newpartner-email',
    },
    {
      label: 'meterPoint.meterPointChangeRequest.contractPartnerType.forms.privatePerson.phone',
      value: privatePartner?.phone || EMPTY_VALUE,
      contentDivId: 'meterpoint-change-summary-newpartner-phone',
    },
  ];
};
