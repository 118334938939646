import { inject, Injectable, signal } from '@angular/core';
import { ApiService } from '@ikp/service/api/api.service';
import { API_ROUTE_ZAEHLPUNKTE, getApiUrl, ROUTE_NOT_FOUND } from '@ikp/app.constants';
import { catchError, lastValueFrom, map, Observable, throwError } from 'rxjs';
import { Address } from '@mwe/models';
import { MeterPoint } from '@ikp/model/meter-point.model';
import { IAdresse, transformAdresseToAddress } from '@shared/model/address.model';
import {
  IFindZaehlpunktFilter,
  IFindZaehlpunktResponse,
  IPreisbestandteil,
  IZaehlpunkt,
  transformResponseToMeterPoint,
  transformResponseToMeterPoints,
} from '@ikp/model/meter-point-http.model';
import { IkpPopupService } from '@ikp/service/popup/ikp-popup.service';
import { Router } from '@angular/router';
import { homeRoutePrefix } from '@shared/app.constants';

@Injectable({
  providedIn: 'root',
})
export class MeterPointService extends ApiService {
  private readonly popupService = inject(IkpPopupService);
  private readonly router = inject(Router);
  meterPoints = signal<MeterPoint[]>([]);
  meterPointsOfAddress = signal<MeterPoint[]>([]);
  allAddresses = signal<Address[]>([]);

  page = signal<number>(1);
  totalCount = signal<number>(0);
  totalPages = signal<number>(1);

  loadMeterPoints(requestBody: IFindZaehlpunktFilter, page: number, size: number): Promise<MeterPoint[]> {
    // backend starts counting pages from 0
    page -= 1;
    return lastValueFrom(
      this.httpClient.post<IFindZaehlpunktResponse>(getApiUrl(this.apiUrl, API_ROUTE_ZAEHLPUNKTE), requestBody, {
        params: { page: page.toString(), size: size.toString() },
      }),
    )
      .then((findZaehlpunktResponse: IFindZaehlpunktResponse) => {
        const meterpoints: MeterPoint[] = transformResponseToMeterPoints(findZaehlpunktResponse);
        this.meterPoints.set(meterpoints);
        this.totalPages.set(findZaehlpunktResponse.page.totalPages);
        this.page.set(findZaehlpunktResponse.page.number + 1);
        this.totalCount.set(findZaehlpunktResponse.page.totalElements);

        return meterpoints;
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.error('Error fetching zaehlpunkte:', error);
        this.handleErrorMeterpoints();
        return [];
      });
  }

  loadMeterPointsOfAddress(requestBody: Address): Observable<MeterPoint[]> {
    return this.httpClient.post<IZaehlpunkt[]>(getApiUrl(this.apiUrl, API_ROUTE_ZAEHLPUNKTE + '/search/address'), requestBody).pipe(
      map((findZaehlpunktResponse: IZaehlpunkt[]) => {
        const meterpoints: MeterPoint[] = findZaehlpunktResponse.map(zaehlpunkt => transformResponseToMeterPoint(zaehlpunkt));
        this.meterPointsOfAddress.set(meterpoints);
        return meterpoints;
      }),
      catchError(error => {
        // eslint-disable-next-line no-console
        console.error('Error fetching zaehlpunkte:', error);
        this.handleErrorMeterpoints();
        return throwError(() => new Error(error));
      }),
    );
  }

  loadMeterPoint(id: string): Promise<void | MeterPoint> {
    // get meter point by id
    return lastValueFrom(this.httpClient.get(getApiUrl(this.apiUrl, API_ROUTE_ZAEHLPUNKTE) + `/${id}`))
      .then((findZaehlpunktResponse: IZaehlpunkt) => {
        const meterPoint: MeterPoint = transformResponseToMeterPoint(findZaehlpunktResponse);
        this.meterPoints.set([meterPoint]);
        return meterPoint;
      })
      .catch(error => {
        if (error.status === 400) {
          this.router.navigate([homeRoutePrefix(ROUTE_NOT_FOUND)]);
          return;
        }
        // eslint-disable-next-line no-console
        console.error('Error fetching zaehlpunkt:', error);
        this.handleErrorMeterpoint();
        throw error;
      });
  }

  loadMeterPointCount(requestBody?: IFindZaehlpunktFilter): Observable<number> {
    return this.httpClient.post<number>(getApiUrl(this.apiUrl, `${API_ROUTE_ZAEHLPUNKTE}/count`), requestBody).pipe(
      catchError(error => {
        // eslint-disable-next-line no-console
        console.error('Error fetching meter-points count:', error);
        this.handleErrorMeterpoints();
        return [];
      }),
    );
  }

  loadAllAddresses(): Promise<Address[]> {
    return lastValueFrom(this.httpClient.get<IAdresse[]>(getApiUrl(this.apiUrl, `${API_ROUTE_ZAEHLPUNKTE}/adressen`)))
      .then((addressData: IAdresse[]) => {
        const addresses = addressData.map(address => {
          return transformAdresseToAddress(address);
        });
        this.allAddresses.set(addresses);
        return addresses;
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.error('Error fetching addresses:', error);
        this.handleErrorMeterpoints();
        return [];
      });
  }

  loadPriceInformation(id: string): Observable<IPreisbestandteil[]> {
    return this.httpClient.get<IPreisbestandteil[]>(getApiUrl(this.apiUrl, `${API_ROUTE_ZAEHLPUNKTE}/${id}/preise`)).pipe(
      catchError(error => {
        // eslint-disable-next-line no-console
        console.error('Error fetching price information:', error);
        this.handleErrorMeterpoint();
        return [];
      }),
    );
  }

  private handleErrorMeterpoint(): void {
    this.popupService.handleErrorPopup('download-error', 'global.errors.meterpoint.title');
  }

  private handleErrorMeterpoints(): void {
    this.popupService.handleErrorPopup('download-error', 'global.errors.meterpoints.title');
  }
}
