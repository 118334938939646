import { IAdresse, IAdresseFilter, transformAdresseToAddress } from '@shared/model/address.model';
import { MeterPoint, UmmeldungsStatus } from './meter-point.model';
import { Page } from '@shared/model/page.model';
import { MeterpointStatus } from '@ikp/model/meterpoint-status.model';

export interface IFindZaehlpunktFilter {
  zaehlpunktStatus: string;
  sparte: string;
  adresseFilter: IAdresseFilter;
}

export interface IZaehlpunkt {
  zaehlpunktId: string;
  zaehlpunktnummer: string;
  status: string;
  zaehlerNr: string;
  geschaeftspartnerId: string;
  geschaeftspartnerNr: string;
  sparte: string;
  adresse: IAdresse;
  referenzname: string;

  verbrauchspreis?: string;
  vertragsbeginn?: string;
  vertragsbindung?: string;
  vertragsende?: string;
  vertragskontonummer?: string;
  vertragsnummer?: string;

  iban?: string;
  bezahlart?: string;
  grundpreis?: string;
  rechnungsAdresse?: IAdresse;
  rechnungsempfaengerName?: string;
  rechnungsversandArt?: string;
  rechnungsempfaengerEmail?: string;

  tarifName?: string;
  unternehmenId?: string;
  ummeldungsStatus: UmmeldungsStatus;
}

export type IFindZaehlpunktResponse = Page<IZaehlpunkt>;

export interface IPreisbestandteil {
  preistyp: string;
  beschreibung: string;
  wert: string;
  einheit: string;
}

export function transformResponseToMeterPoints(findZaehlpunktResponse: IFindZaehlpunktResponse): MeterPoint[] {
  // transform address object to MWE Address class
  return findZaehlpunktResponse.content.map((zaehlpunktRaw: IZaehlpunkt) => transformResponseToMeterPoint(zaehlpunktRaw));
}

export function transformResponseToMeterPoint(zaehlpunktResponse: IZaehlpunkt): MeterPoint {
  // transform address object to MWE Address class
  const address = transformAdresseToAddress(zaehlpunktResponse.adresse);
  const rechnungsAdresse = transformAdresseToAddress(zaehlpunktResponse.rechnungsAdresse);

  return new MeterPoint({
    ...zaehlpunktResponse,
    status: zaehlpunktResponse.status as MeterpointStatus,
    adresse: address,
    rechnungsAdresse: rechnungsAdresse,
  });
}
