export enum ProductCategory {
  GAS = 'Gas',
  STROM = 'Strom',
}

export class MeterPointSelection {
  id: string;
  category: ProductCategory;
  meterPointNumber: string;
  meterNumber: string;
  // no calculations with meterCount, only used as field in PDF
  meterCount: string;
}

export enum ReRegistrationDateType {
  NEXT_POSSIBLE_DATE = 'NEXT_POSSIBLE_DATE',
  SPECIFIC_DATE = 'SPECIFIC_DATE',
}

export const SELECTED_METER_POINT_PRODUCTS = 'selectedMeterPointProducts';
