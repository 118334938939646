import { Component, input, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiComponentsModule } from '@mwe/ui/components';

@Component({
  selector: 'app-ikp-basic-card',
  templateUrl: './basic-card.component.html',
  styleUrls: ['./basic-card.component.scss'],
  standalone: true,
  imports: [CommonModule, UiComponentsModule],
  //eslint-disable-next-line
  encapsulation: ViewEncapsulation.None,
})
export class BasicCardComponent {
  title = input<string>();
  hasBackground = input<boolean>();
}
