import { Component, computed, HostListener, input, ModelSignal, signal } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { UiComponentsModule } from '@mwe/ui/components';
import { NgbDatepicker, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import dayjs from 'dayjs';
import { getDayJsFromNgbDateStruct, getNgbDateStructFromDayJs } from '@mwe/utils';
import { DateInputMaskDirective } from '@shared/directives/date-input-mask.directive';
import { CdkTrapFocus } from '@angular/cdk/a11y';

export type DatepickerConfig = {
  label: string;
  placeholder: string;
  currentValue: ModelSignal<NgbDateStruct>;
  onValueChange: (value: NgbDateStruct) => void;
};

@Component({
  selector: 'lib-ikp-datepicker',
  standalone: true,
  imports: [CommonModule, UiComponentsModule, FormsModule, NgbDatepicker, DateInputMaskDirective, CdkTrapFocus],
  providers: [DatePipe],
  templateUrl: './datepicker.component.html',
  styleUrl: './datepicker.component.scss',
})
export class DatepickerComponent {
  private datePipe: DatePipe;

  isInvalidSelection = input(false);
  invalidDateMessageKey = input<string>('');

  maximumDate = input<NgbDateStruct>();
  minimumDate = input<NgbDateStruct>();

  protected isCalendarOpen = signal<boolean>(false);
  maxDate = computed<NgbDateStruct>(() => this.maximumDate() || getNgbDateStructFromDayJs(dayjs()));
  minDate = computed<NgbDateStruct>(() => this.minimumDate() || getNgbDateStructFromDayJs(dayjs().subtract(6, 'year')));

  datePickerConfig = input.required<DatepickerConfig>();

  constructor() {
    this.datePipe = new DatePipe('de-DE');
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      event.preventDefault();
      this.openCalendar();
    }
  }

  get label(): string {
    return this.datePickerConfig().label;
  }

  get placeholder(): string {
    return this.datePickerConfig().placeholder;
  }

  get currentDateValue(): string {
    return this.datePickerConfig().currentValue()
      ? getDayJsFromNgbDateStruct(this.datePickerConfig().currentValue()).format('DD.MM.YYYY')
      : null;
  }

  get dateHeader(): string {
    const currentDate = getDayJsFromNgbDateStruct(this.datePickerConfig().currentValue() || this.maxDate()).format('YYYY-MM-DD');
    const pipedDate = this.datePipe.transform(currentDate, 'EEE, dd. MMMM', undefined, 'de-DE');
    return this.cleanDate(pipedDate);
  }

  get yearHeader(): number {
    return this.datePickerConfig().currentValue()?.year || this.maxDate().year;
  }

  get today(): NgbDateStruct {
    return getNgbDateStructFromDayJs(dayjs());
  }

  cleanDate(date: string | null): string {
    return date ? date.replace(/[.]/, '') : '';
  }

  toggleCalendar() {
    if (this.isCalendarOpen()) {
      this.closeCalendar();
    } else {
      this.openCalendar();
    }
  }

  openCalendar() {
    this.isCalendarOpen.set(true);
    if (this.datePickerConfig().currentValue() === undefined) {
      this.datePickerConfig().onValueChange(this.today);
    }
  }

  closeCalendar() {
    this.isCalendarOpen.set(false);
  }

  protected readonly getDayJsFromNgbDateStruct = getDayJsFromNgbDateStruct;
}
