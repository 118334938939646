import { Address } from '@mwe/models';
import { MeterpointStatus } from '@ikp/model/meterpoint-status.model';

export interface IMeterPoint {
  zaehlpunktId: string;
  zaehlpunktnummer: string;
  status: MeterpointStatus;
  zaehlerNr: string;
  geschaeftspartnerId: string;
  geschaeftspartnerNr: string;
  sparte: string;
  adresse: Address;
  referenzname: string;

  verbrauchspreis?: string;
  vertragsbeginn?: string;
  vertragsbindung?: string;
  vertragsende?: string;
  vertragskontonummer?: string;
  vertragsnummer?: string;

  iban?: string;
  bezahlart?: string;
  grundpreis?: string;
  rechnungsAdresse?: Address;
  rechnungsempfaengerName?: string;
  rechnungsversandArt?: string;
  rechnungsempfaengerEmail?: string;

  tarifName?: string;
  unternehmenId?: string;

  ummeldungsStatus: UmmeldungsStatus;
}

export enum UmmeldungsStatus {
  WARTEN_AUF_UNTERSCHRIFT = 'WARTEN_AUF_UNTERSCHRIFT',
  KEINE_LAUFENDER_UMMELDEPROZESS = 'KEINE_LAUFENDER_UMMELDEPROZESS',
}

export class MeterPoint implements IMeterPoint {
  id: string;
  zaehlpunktId: string;
  zaehlpunktnummer: string;
  status: MeterpointStatus;
  zaehlerNr: string;
  geschaeftspartnerId: string;
  geschaeftspartnerNr: string;
  sparte: string;
  adresse: Address;
  referenzname: string;

  verbrauchspreis?: string;
  vertragsbeginn?: string;
  vertragsbindung?: string;
  vertragsende?: string;
  vertragskontonummer?: string;
  vertragsnummer?: string;

  iban?: string;
  bezahlart?: string;
  grundpreis?: string;
  rechnungsAdresse: Address;
  rechnungsempfaengerName?: string;
  rechnungsversandArt?: string;
  rechnungsempfaengerEmail?: string;

  tarifName?: string;
  unternehmenId?: string;

  ummeldungsStatus: UmmeldungsStatus;

  constructor(meterPoint: IMeterPoint) {
    this.id = meterPoint.zaehlpunktId;
    this.zaehlpunktId = meterPoint.zaehlpunktId;
    this.zaehlpunktnummer = meterPoint.zaehlpunktnummer;
    this.status = meterPoint.status;
    this.ummeldungsStatus = meterPoint.ummeldungsStatus;
    this.zaehlerNr = meterPoint.zaehlerNr;
    this.geschaeftspartnerId = meterPoint.geschaeftspartnerId;
    this.geschaeftspartnerNr = meterPoint.geschaeftspartnerNr;
    this.sparte = meterPoint.sparte;
    this.adresse = meterPoint.adresse;
    this.referenzname = meterPoint.referenzname;

    this.verbrauchspreis = meterPoint.verbrauchspreis;
    this.vertragsbeginn = meterPoint.vertragsbeginn;
    this.vertragsbindung = meterPoint.vertragsbindung;
    this.vertragsende = meterPoint.vertragsende;

    this.vertragskontonummer = meterPoint.vertragskontonummer;
    this.vertragsnummer = meterPoint.vertragsnummer;
    this.iban = meterPoint.iban;
    this.bezahlart = meterPoint.bezahlart;
    this.grundpreis = meterPoint.grundpreis;
    this.rechnungsAdresse = meterPoint.rechnungsAdresse;
    this.rechnungsempfaengerName = meterPoint.rechnungsempfaengerName;
    this.tarifName = meterPoint.tarifName;
    this.unternehmenId = meterPoint.unternehmenId;
    this.rechnungsversandArt = meterPoint.rechnungsversandArt;
    this.rechnungsempfaengerEmail = meterPoint.rechnungsempfaengerEmail;
  }

  getZaehlpunktnummerShort(): string {
    return this.zaehlpunktnummer.slice(0, 5) + '.......' + this.zaehlpunktnummer.slice(-8);
  }

  isUmmeldungInProgress(): boolean {
    return this.ummeldungsStatus === UmmeldungsStatus.WARTEN_AUF_UNTERSCHRIFT;
  }
}
