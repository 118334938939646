import { Component, OnInit } from '@angular/core';
import { UiComponentsModule } from '@mwe/ui/components';
import { RouterModule } from '@angular/router';
import { ROUTE_FAQ, ROUTE_HOME, ROUTE_INVOICES, ROUTE_METER_POINTS, ROUTE_PROFILE_DATA, ROUTE_TERMS_OF_USE } from '@ikp/app.constants';
import { PathAccessPermissionsDirective } from '@ikp/directives/path-access-permissions.directive';
import { homeRoutePrefix, routePrefix } from '@shared/app.constants';

@Component({
  selector: 'app-ikp-footer',
  standalone: true,
  imports: [RouterModule, UiComponentsModule, PathAccessPermissionsDirective],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss',
})
export class FooterComponent implements OnInit {
  protected readonly homeRoutePrefix = homeRoutePrefix;
  protected readonly ROUTE_METER_POINTS = ROUTE_METER_POINTS;
  protected readonly ROUTE_INVOICES = ROUTE_INVOICES;
  protected readonly ROUTE_PROFILE_DATA = ROUTE_PROFILE_DATA;
  protected readonly ROUTE_FAQ = ROUTE_FAQ;
  protected readonly ROUTE_HOME = ROUTE_HOME;
  protected readonly ROUTE_TERMS_OF_USE = ROUTE_TERMS_OF_USE;

  currentYear: number;
  links = {
    defaultRoute: `/`,
  };

  ngOnInit(): void {
    this.setCurrentYear();
  }

  setCurrentYear(): void {
    this.currentYear = new Date().getFullYear();
  }

  protected readonly routePrefix = routePrefix;
}
