import { MeterPointChangeState } from './meterpoint-change-request.types';
import {
  ROUTE_CHANGE_TYPE,
  ROUTE_CONFIRMATION,
  ROUTE_CURRENT_CONTRACT_PARTNER,
  ROUTE_HOME,
  ROUTE_METER_POINT_CHANGE_REQUEST,
  ROUTE_METER_POINTS,
  ROUTE_NEW_CONTRACT_PARTNER,
  ROUTE_PDF_UPLOAD,
  ROUTE_PRODUCTS_AND_DATE,
  ROUTE_SUMMARY,
} from '@ikp/app.constants';
import { IPopupModel, IWizardStep } from '@mwe/models';
import { IkpPopupService } from '@ikp/service/popup/ikp-popup.service';

import { Router } from '@angular/router';
import { SignalState } from '@shared/service/signal-state-service/signal-state.service';
import { AddressForm } from '@ikp/pages/meter-point-change-request/03_current_contract_partner/model/occupied_contract_partner.model';
import { getAddressPostCodeAndStreetFormatted } from '@mwe/utils';
import { EMPTY_VALUE } from '@ikp/pages/meter-point-change-request/05_summary/summary.utils';

export enum MeterPointChangeType {
  EMPTY_TO_OCCUPIED = 'EMPTY_TO_OCCUPIED',
  OCCUPIED_TO_EMPTY = 'OCCUPIED_TO_EMPTY',
  TENANT_CHANGE = 'TENANT_CHANGE',
}

export enum ContractPartnerType {
  PRIVATE = 'PRIVATE',
  COMPANY = 'COMPANY',
}

export interface MeterPointChangeTypeOption {
  id: MeterPointChangeType;
  text: string;
}

export const meterPointChangeContractPartnerOptions = [
  {
    id: ContractPartnerType.PRIVATE,
    text: 'meterPoint.meterPointChangeRequest.contractPartnerType.privateperson',
  },
  {
    id: ContractPartnerType.COMPANY,
    text: 'meterPoint.meterPointChangeRequest.contractPartnerType.companyOrOrganisation',
  },
];

export const meterPointChangeOptions = [
  {
    id: MeterPointChangeType.EMPTY_TO_OCCUPIED,
    text: 'meterPoint.meterPointChangeRequest.changeType.emptyToOccupied',
  },
  {
    id: MeterPointChangeType.OCCUPIED_TO_EMPTY,
    text: 'meterPoint.meterPointChangeRequest.changeType.occupiedToEmpty',
  },
  {
    id: MeterPointChangeType.TENANT_CHANGE,
    text: 'meterPoint.meterPointChangeRequest.changeType.tenantChange',
  },
];

export const initialMeterPointChangeRequestState: MeterPointChangeState = {
  stepChangeType: MeterPointChangeType.EMPTY_TO_OCCUPIED,
  currentContractPartner: null,
  meterPoint: null,
  newContractPartner: {
    emptyPartner: null,
    formData: {
      partnerType: ContractPartnerType.PRIVATE,
    },
    alternativeInvoicingAddress: null,
  },
  productAndDate: {
    reRegistrationDateSelection: null,
    reRegistrationDate: null,
    meterPoints: [],
  },
};

const PREV_LABEL = 'meterPoint.meterPointChangeRequest.wizard.prev';
const NEXT_LABEL = 'meterPoint.meterPointChangeRequest.wizard.next';
const CANCEL_LABEL = 'meterPoint.meterPointChangeRequest.wizard.cancel';
const CLOSE_LABEL = 'meterPoint.meterPointChangeRequest.wizard.close';

const getRoute = (childRoute: string): string => {
  return `/${ROUTE_METER_POINT_CHANGE_REQUEST}/${childRoute}`;
};

export const getMeterPointChangeRequestSteps = (storeState: MeterPointChangeState): IWizardStep[] => {
  const steps = [
    {
      titleKey: 'meterPoint.meterPointChangeRequest.title',
      path: getRoute(ROUTE_CHANGE_TYPE),
      nextButtonLabel: NEXT_LABEL,
      prevButtonLabel: CANCEL_LABEL,
      progressBarLabel: 'meterPoint.meterPointChangeRequest.stepChangeType',
    },
    {
      titleKey: meterPointChangeOptions.find(option => option.id === storeState.stepChangeType)?.text,
      path: getRoute(ROUTE_PRODUCTS_AND_DATE),
      nextButtonLabel: NEXT_LABEL,
      prevButtonLabel: PREV_LABEL,
      progressBarLabel: 'meterPoint.meterPointChangeRequest.stepProductsAndDate',
    },
    {
      titleKey: meterPointChangeOptions.find(option => option.id === storeState.stepChangeType)?.text,
      path: getRoute(ROUTE_CURRENT_CONTRACT_PARTNER),
      nextButtonLabel: NEXT_LABEL,
      prevButtonLabel: PREV_LABEL,
      progressBarLabel: 'meterPoint.meterPointChangeRequest.currentContractPartner',
    },
    {
      titleKey: meterPointChangeOptions.find(option => option.id === storeState.stepChangeType)?.text,
      path: getRoute(ROUTE_NEW_CONTRACT_PARTNER),
      nextButtonLabel: NEXT_LABEL,
      prevButtonLabel: PREV_LABEL,
      progressBarLabel: 'meterPoint.meterPointChangeRequest.newContractPartner',
    },
    {
      titleKey: 'meterPoint.meterPointChangeRequest.summary.title',
      subTitleKey: 'meterPoint.meterPointChangeRequest.summary.subtitle',
      path: getRoute(ROUTE_SUMMARY),
      nextButtonLabel: NEXT_LABEL,
      prevButtonLabel: PREV_LABEL,
      progressBarLabel: 'meterPoint.meterPointChangeRequest.summary.progressBarLabel',
    },
    {
      path: getRoute(ROUTE_CONFIRMATION),
      nextButtonLabel: CLOSE_LABEL,
      hidePrevButton: true,
      progressBarLabel: 'meterPoint.meterPointChangeRequest.confirmation.progressBarLabel',
    },
  ] as IWizardStep[];

  // Add PDF upload step if the change type is not OCCUPIED_TO_EMPTY
  if (storeState.stepChangeType !== MeterPointChangeType.OCCUPIED_TO_EMPTY) {
    const pdfUploadStep = {
      titleKey: meterPointChangeOptions.find(option => option.id === storeState.stepChangeType)?.text,
      path: getRoute(ROUTE_PDF_UPLOAD),
      nextButtonLabel: 'meterPoint.meterPointChangeRequest.wizard.uploadLater',
      hidePrevButton: true,
      hideFromProgressBar: true,
    } as IWizardStep;

    steps.splice(-1, 0, pdfUploadStep);
  }

  return steps;
};

export const getZaehlpunktnummerShort = (zaehlpunktnummer: string) => {
  return zaehlpunktnummer.slice(0, 5) + '.......' + zaehlpunktnummer.slice(-8);
};

export const closeMeterPointChangeRequestWizard = (
  popupService: IkpPopupService,
  router: Router,
  wizardStore: SignalState<MeterPointChangeState>,
  noPopup = false,
  extraAction?: () => void,
) => {
  if (noPopup) {
    void router.navigate([`/${ROUTE_HOME}/${ROUTE_METER_POINTS}`]);
    wizardStore.reset();
  } else {
    popupService.handleGenericPopup(meterPointChangeRequestWarningPopupModel);
    const popupSubscription = popupService.events().subscribe(result =>
      result
        ? router
            .navigate([`/${ROUTE_HOME}/${ROUTE_METER_POINTS}`])
            .then(() => wizardStore.reset())
            .then(() => (extraAction ? extraAction() : null))
            .then(() => popupSubscription.unsubscribe())
        : popupSubscription.unsubscribe(),
    );
  }
  return true;
};

export const meterPointChangeRequestWarningPopupModel: IPopupModel = {
  id: 'warning-popup',
  titleKey: 'meterPoint.meterPointChangeRequest.wizard.closeWarning.title',
  messageKey: 'meterPoint.meterPointChangeRequest.wizard.closeWarning.message',
  showSubmitButton: true,
  submitButtonKey: 'meterPoint.meterPointChangeRequest.wizard.closeWarning.buttonExit',
  showCancelButton: true,
  cancelButtonKey: 'meterPoint.meterPointChangeRequest.wizard.closeWarning.buttonStay',
  iconColorClass: 'red',
  iconTypeClass: 'fa-bell',
};

export const preparePartnerAddressForFormatter = (address: AddressForm) =>
  getAddressPostCodeAndStreetFormatted({
    postcode: address?.plz,
    city: address?.city,
    street: address?.street,
    doorNumber: address?.door,
    streetNumber: address?.houseNumberStaircase,
    block: undefined,
    country: undefined,
    id: undefined,
  }) || EMPTY_VALUE;
