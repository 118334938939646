import { inject, Injectable } from '@angular/core';
import { ApiService } from '@ikp/service/api/api.service';
import {
  API_ROUTE_UMMELDUNG,
  API_ROUTE_UMMELDUNG_DOCUMENT,
  API_ROUTE_ZAEHLPUNKTE,
  getApiUrl,
  PDF_FILE_EXTENSION,
  UMMELDUNG_DATE_FORMAT,
  UMMELDUNG_FILE_NAME_PREFIX,
  UMMELDUNG_FILE_ZNR,
} from '@ikp/app.constants';
import { map, Observable, switchMap } from 'rxjs';
import { FileModel } from '@shared/components/inputs/file-input/file-input.model';
import {
  MeterPointChangeDocumentResponse,
  MeterPointChangeState,
} from '@ikp/pages/meter-point-change-request/meterpoint-change-request.types';
import { IkpPopupService } from '@ikp/service/popup/ikp-popup.service';
import { catchError } from 'rxjs/operators';
import dayjs from 'dayjs';
import { MeterPointChangeTransformer } from '@ikp/service/meter-point/meter-point-change.transformer';

@Injectable({
  providedIn: 'root',
})
export class MeterPointChangeService extends ApiService {
  private popupService = inject(IkpPopupService);

  public uploadSignedMeterpointChange(meterpointNumber: string, fileToUpload: FileModel): Observable<void> {
    const url = `${getApiUrl(this.apiUrl, API_ROUTE_ZAEHLPUNKTE)}/${meterpointNumber}/${API_ROUTE_UMMELDUNG_DOCUMENT}`;
    const formData = new FormData();
    formData.append('file', fileToUpload.originalFile, fileToUpload.name);
    return this.httpClient.post(url, formData).pipe(map(() => null));
  }

  public deleteMeterpointChange(meterPointNumber: string): Observable<void> {
    const url = `${getApiUrl(this.apiUrl, API_ROUTE_ZAEHLPUNKTE)}/${meterPointNumber}/${API_ROUTE_UMMELDUNG}`;
    return this.httpClient.delete(url).pipe(map(() => null));
  }

  public getMeterPointChangeRequest(meterPointNumber: string): Observable<Blob> {
    const apiBasePath = getApiUrl(this.apiUrl, `${API_ROUTE_ZAEHLPUNKTE}/${meterPointNumber}`);
    return this.httpClient
      .get(`${apiBasePath}/${API_ROUTE_UMMELDUNG_DOCUMENT}`, {
        responseType: 'blob',
        observe: 'response',
      })
      .pipe(map(response => response.body));
  }

  public createDocument(meterPointChangeState: MeterPointChangeState): Observable<MeterPointChangeDocumentResponse> {
    const meterPointNumber = meterPointChangeState.productAndDate.meterPoints.at(0).meterPointNumber;
    const apiBasePath = getApiUrl(this.apiUrl, `${API_ROUTE_ZAEHLPUNKTE}/${meterPointNumber}`);
    const meterPointChangeRequestPayload = MeterPointChangeTransformer.mapToIMeterPointChangeRequest(meterPointChangeState);

    return this.httpClient.put(`${apiBasePath}/${API_ROUTE_UMMELDUNG}`, meterPointChangeRequestPayload).pipe(
      switchMap(() => this.getMeterPointChangeRequest(meterPointNumber)),
      map(blob => ({ blob: blob, filename: this.composeDocumentName(meterPointNumber) })),
      catchError(() => {
        this.handleDownloadErrorPopup();
        return Promise.reject();
      }),
    );
  }

  public cancelMeterPointChangeRequest(meterPointNumber: string): Observable<void> {
    return this.getMeterPointChangeRequest(meterPointNumber).pipe(
      switchMap(() => this.deleteMeterpointChange(meterPointNumber)),
      catchError(errorResponse => {
        this.handleGenericErrorPopup('delete-error');
        throw errorResponse;
      }),
    );
  }

  public handleDownloadErrorPopup(): void {
    this.popupService.handleGenericPopup({
      id: 'download-error',
      titleKey: 'global.errors.meterpointChangeRequestDownload.title',
      messageKey: 'global.errors.meterpointChangeRequestDownload.message',
      showSubmitButton: true,
      showCancelButton: true,
      submitButtonKey: 'global.errors.meterpointChangeRequestDownload.primaryLink',
      cancelButtonKey: 'global.errors.meterpointChangeRequestDownload.secondaryLink',
      iconColorClass: 'red',
      iconTypeClass: 'fa-bell',
    });
  }

  public handleGenericErrorPopup(id: string): void {
    this.popupService.handleErrorPopup(id, 'global.errors.meterpointChangeRequest.defaultErrorTitle');
  }

  private composeDocumentName(meterPointNumber: string): string {
    const date = dayjs().format(UMMELDUNG_DATE_FORMAT);
    return [UMMELDUNG_FILE_NAME_PREFIX, date, UMMELDUNG_FILE_ZNR, meterPointNumber, PDF_FILE_EXTENSION].join('');
  }
}
