import { Component, Input } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';

@Component({
  selector: 'lib-ikp-header-menu',
  standalone: true,
  imports: [CommonModule, NgOptimizedImage],
  templateUrl: './header-menu.component.html',
  styles: ['img { cursor: pointer; }'],
})
export class HeaderMenuComponent {
  @Input() isCollapsed: boolean;
}
