<div
  (mweClickOutside)="closeCalendar()"
  class="datepicker-container"
  [ngClass]="{ 'pseudo-focus': isCalendarOpen(), 'custom-input-invalid-state': isInvalidSelection() }"
>
  <span class="datepicker-input-container">
    <label for="date" class="datepicker-label">{{ label | translate }}</label>
    <input
      libDateInputMask
      [maxDate]="getDayJsFromNgbDateStruct(maxDate())"
      [minDate]="getDayJsFromNgbDateStruct(minDate())"
      (applyDate)="datePickerConfig().onValueChange($event)"
      id="date"
      type="text"
      class="datepicker-input"
      [value]="currentDateValue"
      maxlength="10"
      [placeholder]="placeholder | translate"
    />
  </span>
  <button tabindex="-1" class="btn-icon-calendar btn-clean" (click)="toggleCalendar()">
    <i class="fa-solid fa-calendar-days"></i>
  </button>
  @if (this.isCalendarOpen()) {
    <div class="dropdown-container" cdkTrapFocus>
      <div class="datepicker centered-datepicker-content">
        <div class="datepicker-header">
          <p class="title">{{ 'invoices.filterPopup.period.datepicker.headerTitle' | translate }}</p>
          <p class="year">{{ yearHeader }}</p>
          <h2 class="fulldate">{{ dateHeader }}</h2>
        </div>
        <ngb-datepicker
          (ngModelChange)="datePickerConfig().onValueChange($event)"
          [ngModel]="datePickerConfig().currentValue()"
          [startDate]="datePickerConfig().currentValue() || today"
          [minDate]="minDate()"
          [maxDate]="maxDate()"
          [footerTemplate]="dpft"
        />
      </div>
    </div>
  }
</div>
@if (invalidDateMessageKey()) {
  <div class="invalid-feedback" [ngClass]="{ 'd-block': isInvalidSelection() }">
    {{ invalidDateMessageKey() | translate }}
  </div>
}
<ng-template #dpft>
  <div class="datepicker-footer">
    <button id="mwe-datepicker-submit" type="button" class="btn btn-link btn-link-primary text-uppercase" (click)="closeCalendar()">
      {{ 'invoices.filterPopup.period.datepicker.applyDate' | translate }}
    </button>
  </div>
</ng-template>
