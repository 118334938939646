<header class="bg-secondary header-bg-ikp mb-5 mb-lg-6">
  <nav class="navbar navbar-expand-lg header-container">
    <div class="header-row">
      <div class="col-2 logo-col">
        <a class="logo-link" data-test="header-logo-link" [routerLink]="startPageLink()">
          <lib-ikp-header-logo />
        </a>
      </div>
      @if (isLoggedIn()) {
        <div class="col-12 text-end">
          <div class="header-right-container">
            <lib-ikp-header-logout class="lib-ikp-header-logout" [popupService]="popupService()" />
          </div>
        </div>

        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
          (click)="toggleNavbar()"
        >
          <lib-ikp-header-menu [isCollapsed]="isCollapsed()"></lib-ikp-header-menu>
        </button>
      }
    </div>
    <div class="collapse navbar-collapse" id="navbarSupportedContent" [ngbCollapse]="isCollapsed()">
      <div class="navbar-nav mr-auto d-flex d-lg-none bg-white pt-2 position-relative">
        <ng-content></ng-content>
      </div>
    </div>
  </nav>
</header>
