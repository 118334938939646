import { Injectable } from '@angular/core';
import { ApiService } from '@ikp/service/api/api.service';
import { API_ROUTE_FEATURE_FLAGS, getApiUrl } from '@ikp/app.constants';
import { catchError, map, Observable, of, shareReplay } from 'rxjs';
import { FeatureFlag, FeatureFlagResponse } from '@ikp/model/feature-flag.model';

/**
 * Service to fetch feature flag status. Feature flag status is cached in memory as long as the service exists.
 */
@Injectable({
  providedIn: 'root',
})
export class FeatureFlagService extends ApiService {
  private featureFlags = new Map<string, Observable<boolean>>();

  getFeatureFlagStatus(title: FeatureFlag): Observable<boolean> {
    if (this.featureFlags.has(title)) {
      return this.featureFlags.get(title);
    }
    return this.featureFlags.set(title, this.fetchFeatureFlagEnabled(title)).get(title);
  }

  private fetchFeatureFlagEnabled(title: string): Observable<boolean> {
    const url = `${getApiUrl(this.apiUrl, API_ROUTE_FEATURE_FLAGS)}/enabled/${title}`;
    return this.httpClient.get<FeatureFlagResponse>(url).pipe(
      catchError(err => {
        console.error(`Unable to fetch feature flag ${title}`, err);
        return of({ enabled: false });
      }),
      map(v => v.enabled),
      shareReplay(1),
    );
  }
}
