export enum FeatureFlag {
  SPOTTARIF = 'spottarif',
  SUBUSER = 'subuser',
  INVOICE_FILTER_EXTENDED = 'invoice-filter-extended',
  UMMELDUNG = 'meter-point-change-request',
}

export interface FeatureFlagResponse {
  enabled: boolean;
}
